<template>
  <div class="">
    <div class="gg-container">
      <div
        class="search-container"
        style="display: flex;justify-content: space-between;"
      >
        <div class="search-container-fn-input">
          <el-input
            style="width: 200px"
            size="small"
            placeholder="关键字搜索"
            prefix-icon="el-icon-search"
            v-model="searchParams.depart_name"
            clearable
            @change="getList('rest')"
          >
          </el-input>
        </div>
				<el-button
					size="mini"
					type="primary"
					style="padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
					@click="getList('restPage')"
				>
					<span>查找</span>
				</el-button>
        <div style="display: flex;">
          <div
            class="search-container-fn-input"
            style="margin-left: auto"
            v-if="operateList.indexOf('add') > -1"
          >
            <el-button
              size="mini"
              type="success"
              icon="el-icon-plus"
              style="padding: 7px 8px;margin-left: 5px;"
              @click="editItem('add')"
            >
              <span style="font-size: 12px">新增</span>
            </el-button>
          </div>
          <div
            class="search-container-fn-input"
            v-if="operateList.indexOf('delete') > -1"
          >
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-refresh"
              style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									  background-color: #2362FB; border-color: #2362FB"
              @click="delItem(multipleSelectionPres[0])"
              v-if="multipleSelectionPres.length == 1"
              >删除
            </el-button>
          </div>
          <div
            class="search-container-fn-input"
            v-if="operateList.indexOf('update') > -1"
          >
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-refresh"
              style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									  background-color: #2362FB; border-color: #2362FB"
              @click="editItem('edit', multipleSelectionPres[0])"
              v-if="multipleSelectionPres.length == 1"
              >编辑
            </el-button>
          </div>
          <div
            class="search-container-fn-input"
            v-if="operateList.indexOf('update_table') > -1"
          >
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit-outline"
              style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										background-color: #2362FB; border-color: #2362FB"
              @click="handleEditTableHead"
              >编辑表头
            </el-button>
          </div>
          <div
            class="search-container-fn-input"
            v-if="operateList.indexOf('reset_query') > -1"
          >
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-refresh"
              style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										background-color: #2362FB; border-color: #2362FB"
              @click="handleResetSearch"
              >重置查找
            </el-button>
          </div>
        </div>
      </div>

      <el-table
        :tree-props="{ children: 'child', hasChildren: 'hasChildren' }"
        border
        size="mini"
        v-loading="listLoading"
		row-key="id"
        :header-cell-style="{ 'text-align': 'center' }"
        :data="tableData"
        height="725"
        @selection-change="handleSelectionChange"
        style="width: 100%;z-index:0"
      >
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column type="index" align="center" width="50">
        </el-table-column>
        <template v-for="(column, index) in tableHead">
          <el-table-column
            :prop="column.column_prop"
            :label="
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            "
            :width="column.width ? column.width : ''"
            :key="index"
            align="center"
            v-if="column.field_type === 'textBtn' && column.visible === true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <a
                style="font-size: 12px; color: #2379fb"
                @click.prevent="editArticle('view', scope.row)"
              >
                {{ scope.row[column.column_prop] }}
              </a>
            </template>
          </el-table-column>
          <el-table-column
            :prop="column.column_prop"
            :label="
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            "
            :width="column.width ? column.width : ''"
            :key="index"
            align="center"
            v-else-if="
              column.field_type === 'select' && column.visible === true
            "
            show-overflow-tooltip
          >
            <template slot-scope="scope">
				<div v-if="column.column_prop == 'status'">
					<el-switch
							class="switch"
							@change="changeStatus($event,scope.row)"
							v-model="scope.row.status"
							active-text="启用"
							inactive-text="禁用"
							:active-value="1"
							:inactive-value="0"
							active-color="#13ce66"
							inactive-color="#C8C8C8">
						</el-switch>
				</div>
				<div v-else>
					<el-switch
							class="switch"
							@change="changeTopStatus($event,scope.row)"
							v-model="scope.row.is_recom"
							active-text="是"
							inactive-text="否"
							:active-value="1"
							:inactive-value="0"
							active-color="#13ce66"
							inactive-color="#C8C8C8">
						</el-switch>
				</div>
            </template>
          </el-table-column>
          <el-table-column
            :prop="column.column_prop"
            :label="
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            "
            :width="column.width ? column.width : ''"
            :key="index"
            align="center"
            v-else-if="
              column.field_type === 'imageBtn' && column.visible === true
            "
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div
                @click="under(scope.row.icon, 'pic' + scope.$index)"
                v-if="scope.row.icon"
              >
                <a style="color:#2362FB" @click="img">查看图片</a>
                <el-image
                  :ref="'pic' + scope.$index"
                  style="width: 0; height: 0;"
                  :z-index="zIndex"
                  :src="scope.row.icon"
                  :preview-src-list="[scope.row.icon]"
                >
                </el-image>
              </div>
              <div v-else>
                暂无
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :sortable="tableHeadSortable.includes(column.column_prop)"
            :prop="column.column_prop"
            :label="
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            "
            :width="column.width ? column.width : ''"
            :key="index"
            align="center"
            v-else-if="column.visible === true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <a v-if="column.column_prop == 'admin'">
                {{ scope.row.admin.name}}
              </a>
              <a v-else>{{ scope.row[column.column_prop] }}</a>
            </template>
            <template #header>
              <el-popover
                placement="bottom"
                title=""
                min-width="160"
                trigger="click"
                v-if="
                  searchTableHead.filter(
                    (item) => item.name == column.column_prop
                  ).length > 0
                "
              >
                <span slot="reference" class="search-header">
                  <span class="search-title">{{
                    column.column_label_user_definition
                      ? column.column_label_user_definition
                      : column.column_label
                  }}</span>
                  <i
                    style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                    class="el-icon-search"
                    :class="{
                      isSearch: searchTableHead.filter(
                        (item) => item.name == column.column_prop
                      )[0].isSearch,
                      'el-icon-zoom-in': searchTableHead.filter(
                        (item) => item.name == column.column_prop
                      )[0].isSearch,
                    }"
                  />
                </span>
                <el-select
                  v-if="'group_name' == column.column_prop"
                  style="width: 200px"
                  @change="
                    handleSearch(column.column_prop, searchParams.group_name)
                  "
                  v-model="searchParams.group_id"
                  clearable
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in typeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-if="'trigger' == column.column_prop"
                  style="width: 200px"
                  @change="
                    handleSearch(column.column_prop, searchParams.trigger)
                  "
                  v-model="searchParams.trigger"
                  clearable
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-if="'from_type' == column.column_prop"
                  style="width: 200px"
                  @change="
                    handleSearch(column.column_prop, searchParams.from_type)
                  "
                  v-model="searchParams.from_type"
                  clearable
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in from_type_List"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-if="'client' == column.column_prop"
                  style="width: 200px"
                  @change="
                    handleSearch(column.column_prop, searchParams.client)
                  "
                  v-model="searchParams.client_type"
                  clearable
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="(item, index) in clientList"
                    :key="index"
                    :label="item"
                    :value="index"
                  >
                  </el-option>
                </el-select>
              </el-popover>
              <span v-else>{{
                column.column_label_user_definition
                  ? column.column_label_user_definition
                  : column.column_label
              }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        @pagination="getList"
      />
    </div>
    <!--新增编辑 快捷回复-->
    <el-dialog
      :title="dialogTitle + '科室'"
      :visible.sync="dialogVisible"
      width="60%"
      v-if="dialogVisible"
    >
      <el-form
        ref="ruleForm"
        :model="currInfo"
        label-width="80px"
        :rules="rules"
        class="demo-ruleForm"
      >
        <el-form-item label="上级科室" prop="p_id">
          <template v-if="openType == 'add'">
            <el-select v-model="currInfo.p_id" placeholder="请选择">
              <el-option key="0" label="顶级科室" value="0"></el-option>
              <el-option
                class="depart-class"
                v-for="item in top_depart"
                :key="item.id"
                :label="item.depart_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </template>
          <template v-else>
            {{ currInfo.p_name }}
          </template>
        </el-form-item>
        <el-form-item label="科室名称" prop="name">
          <el-input
            v-model="currInfo.name"
            maxlength="15"
            show-word-limit
          ></el-input>
        </el-form-item>

        <el-form-item label="科室编码" prop="code">
          <el-input v-model="currInfo.code"></el-input>
        </el-form-item>

        <el-form-item label="排序" prop="sort">
          <el-input v-model="currInfo.sort"></el-input>
        </el-form-item>

        <!-- <el-form-item label="置顶" prop="is_recom">
          <el-radio v-model="currInfo.is_recom" :label="1">是</el-radio>
          <el-radio v-model="currInfo.is_recom" :label="0">否</el-radio>
        </el-form-item> -->

        <el-form-item label="状态" prop="status">
          <el-radio v-model="currInfo.status" :label="1">启用</el-radio>
          <el-radio v-model="currInfo.status" :label="0">禁用</el-radio>
        </el-form-item>

        <el-form-item label="科室图标">
          <uploadFile
            :fileList="iconPic"
            :data="qiniuData"
            @on-success="uploadPhotoSuccess"
            @on-remove="photoOnRemove"
          >
          </uploadFile>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button type="success" size="small" @click="saveReply('ruleForm')"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!--编辑表头-->
    <editTableHead
      :isActiveEditTableHead.sync="isActiveEditTableHead"
      @_getAdminFieldIndex="_getAdminFieldIndex"
      v-if="isActiveEditTableHead"
      @getList="getList"
      :table_type="table_type"
    >
    </editTableHead>
  </div>
</template>

<script>
import editTableHead from "@/components/editTableHead/editTableHead";
import { getAdminFieldIndex } from "@/api/drugs2.0";
import {
  getHospitalDepartList,
  delHospitalDepart,
  getTopDeparts,
  departStatus,
  departTopStatus,
} from "@/api/basic/index";
import Pagination from "@/components/Pagination"; // secondary package based on el-pagination
import request from "@/utils/request";
import { getQNToken } from "@/api/basic";
import uploadFile from "@/components/upload";
import { mapState } from "vuex";
export default {
  name: "depart",
  components: {
    Pagination,
    uploadFile,
	editTableHead
  },
  computed: {
    dialogTitle() {
      return (
        {
          add: "添加",
          edit: "编辑",
        }[this.openType] || "添加"
      );
    },
    ...mapState({
      table_options: (state) => state.user.table_options,
    })
  },
  async created() {
    await this._getAdminFieldIndex();
    this.getTopDepart();
    this.getList();
    this.getToken();
  },
  mounted() {
    this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      this.operateList = res;
    });
  },
  data() {
    return {
      table_type:'depart',
      operateList: [],
      isActiveEditTableHead: false,
      multipleSelectionPres: [],
      // multipleSelection1: [],
      activities: [],
      tableHead: [],
      searchTableHead: [
        //控制搜索
        {
          name: "appraise_content",
          isSearch: false,
        },
        {
          name: "created_at",
          isSearch: false,
        },
      ],
      tableHeadSortable: [
        //控制排序
        "created_at",
        "expired_at",
        "next_visit_at",
        "end_visit_at",
        "end_order_at",
      ],
      is_recom: 0,
      iconPic: [],
      qiniuData: {
        token: "",
        key: "",
      },
      top_depart: [],
      searchParams: {
        depart_name: "",
      },
      currInfo: {
        is_recom: 0,
        name: "",
        code: null,
        sort: null,
      },
      dialogVisible: false,
      replyContent: "",
      openType: "",
      total: 0,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 20,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      tableData: [],
      ruleForm: {
        name: "",
        code: "",
        sort: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入科室名称", trigger: "blur" },
          {
            min: 2,
            max: 15,
            message: "长度在 2 到 15 个字符",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "请输入科室代码", trigger: "blur" }],
        // is_recom: [
        //   { required: true, message: "请选择是否置顶", trigger: "change" },
        // ],
        sort: [{ required: true, message: "请输入科室排序", trigger: "blur" }],
        status: [{ required: true, message: "请选择状态", trigger: "change" }],
      },
      zIndex: 999,
      flag: false,
    };
  },

  methods: {
    img() {
      this.flag = true;
    },
    // 不同层级指定不同refs
    under(pics, refPic = "pic") {
      // console.log(pics,refPic);
      if (pics.length > 0) {
        console.log(123, this.$refs[refPic][0], 596458645);
        this.zIndex = 9999;
        this.$refs[refPic][0].showViewer = true;
      }
    },
    handleSelectionChange(val) {
      console.log(val);
      let tmp = val.map((v, k, a) => {
        return v.appraise_id;
      });
      this.multipleSelection = `[${tmp.join(",")}]`;
      console.log(this.multipleSelection, 43435435);
      this.multipleSelectionPres = [];
      val.forEach((item) => {
        this.multipleSelectionPres.push(item);
      });
    },
    handleSearch(prop, value) {
      console.log(prop, value, "2312343");
      this.searchTableHead.forEach((item) => {
        if (item.name == prop) {
          if (value == "") {
            item.isSearch = false;
          } else {
            item.isSearch = true;
          }
        }
      });
      this.getList("restPage");
    },
    handleResetSearch() {
      this.searchParams = {
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true;
    },
    async _getAdminFieldIndex() {
      // try {
      //   this.listLoading = true;
      //   var params = {
      //     type: "depart",
      //   };
      //   const res = await getAdminFieldIndex(params);
        this.tableHead = this.table_options.depart;
      //   console.log(this.tableHead);
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false;
      // }
    },
    changeTopStatus(e, row) {
      departTopStatus(row.id, e)
        .then((res) => {
          if (res.code == 200) {
            var msg = e == 1 ? "已置顶" : "取消置顶";
            return this.$message.success(msg);
          } else {
            return this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      console.log(e);
    },
    changeStatus(e, row) {
      departStatus(row.id, e)
        .then((res) => {
          if (res.code == 200) {
            var msg = e == 1 ? "已启用" : "已禁用";
            return this.$message.success(msg);
          } else {
            return this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      console.log(e);
    },
    editStatus(row, status) {
      request({
        url: `/admin/depart/${row.id}/status`,
        method: "put",
        data: { status },
      }).then((res) => {
        if (res.code == 200) {
          row.status = status;
        }
      });
    },
    photoOnRemove(res) {
      this.currInfo.icon = res;
    },
    uploadPhotoSuccess(res) {
      this.currInfo.icon = res;
    },
    getToken() {
      getQNToken()
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            this.qiniuData.token = data.data.token;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTopDepart() {
      getTopDeparts().then((res) => {
        if (res.code == 200) {
          this.top_depart = res.data;
        }
      });
    },
    getList(type) {
      if (type == "rest") {
        this.listQuery.page = 1;
      }
      this.listLoading = true;
      let params = {};
      params.page = this.listQuery.page;
      params.page_size = this.listQuery.limit;
      params.depart_name = this.searchParams.depart_name;
      params.depart_code = this.searchParams.depart_code;
      params.created_at = this.searchParams.created_at;
      params.status = this.searchParams.status;
      params.is_recom = this.searchParams.is_recom;

      getHospitalDepartList(params)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.total = _data.total;
            this.listQuery.limit = _data.per_page;
            this.tableData = _data.data;
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    },
    editItem(type, item) {
      this.openType = type;
      this.currInfo = {};
      if (type === "add") {
        this.icon = [];
        this.iconPic = [];
        if (item) {
          this.currInfo.p_id = item.id;
        }
        console.log(item);
      } else {
        if (!item) {
          this.$message({
            type: "error",
            message: "请选择要修改的科室",
          });
          return;
        }
        item.icon ? (this.iconPic = [item.icon]) : (this.iconPic = []);

        this.currInfo = Object.assign({}, item);
        // this.currInfo.name = item.depart_name
        this.currInfo = {
          name: item.depart_name,
          code: item.depart_code,
          sort: item.sort,
          id: item.id,
          icon: item.icon,
          is_recom:0,
          status: item.status,
        };

        if (item.p_id == 0) {
          this.currInfo.p_name = "顶级科室";
        } else {
          let curDepart = this.top_depart.find((v) => v.id == item.p_id);
          this.currInfo.p_name = curDepart ? curDepart.depart_name : "";
        }
      }
      this.dialogVisible = true;
    },
    delItem(item) {
      let params = {};
      params.id = item.id;
      this.$confirm(`是否删除科室： "${item.depart_name}"？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      })
        .then(() => {
          delHospitalDepart(params)
            .then((response) => {
              let data = response;
              if (data.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getList();
              } else {
                this.$message({
                  type: "error",
                  message: data.msg ? data.msg : data.message,
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch(() => {});
    },
    saveReply(form) {
      this.$refs[form].validate((valid) => {
        if (!valid) {
          return;
        } else {
          let params = {};
          let url = "";
          params.depart_name = this.currInfo.name;
          params.depart_code = this.currInfo.code;
          params.sort = this.currInfo.sort;
          params.p_id = this.currInfo.p_id;
          params.icon = this.currInfo.icon;
          params.is_recom = 0;
          params.status = this.currInfo.status;
          if (this.openType == "add") {
            url = "/admin/setting/addHospitalDepart"; //添加
          } else {
            //编辑
            url = "/admin/hospital/editHospitalDepart/" + this.currInfo.id;
          }
          request({
            url: url,
            method: "post",
            data: params,
          })
            .then((response) => {
              let data = response;
              if (data.code == 200) {
                if (this.openType == "add") {
                  this.getTopDepart();
                }

                this.$message({
                  type: "success",
                  message: this.dialogTitle + "科室成功！",
                });
                this.getList();
                this.dialogVisible = false;
                //    科室添加或者修改完成后应该刷新state，删除对应的session即可
                sessionStorage.removeItem("_DIC_DEPART_DIC_");
              } else {
                this.$message({
                  type: "error",
                  message: data.msg ? data.msg : data.message,
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
  height: 26px;
  margin-bottom: 10px;

  i {
    width: 25px;
    height: 25px;
    background-color: #2632fb;
    color: white;
    line-height: 25px;
    text-align: center;
    margin-right: 8px;
    border-radius: 2px;
  }
}

.gg-container {
  position: relative;
}

.depart-class {
  padding-left: 35px;
}

.icon-depart {
  display: inline-block;
  margin: 0 10px;
  color: #409eff;
}

.dot {
  display: inline-block;
  border: 5px solid;
  border-radius: 50%;
  margin: 0 5px;
}

/*.title-icon {*/
/*	margin-right: 15px;*/
/*	color: #fff;*/
/*	font-size: 18px;*/
/*	padding: 5px;*/
/*	border-radius: 2px;*/
/*	background: #2362fb;*/
/*}*/

/* switch按钮样式 */
::v-deep.switch {
  .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  /*打开时文字位置设置*/
  .el-switch__label--right {
    z-index: 1;
  }

  /* 调整打开时文字的显示位子 */
  .el-switch__label--right span {
    margin-right: 11px;
  }

  /*关闭时文字位置设置*/
  .el-switch__label--left {
    z-index: 1;
  }

  /* 调整关闭时文字的显示位子 */
  .el-switch__label--left span {
    margin-left: 11px;
  }

  /*显示文字*/
  .el-switch__label.is-active {
    display: block;
  }

  /* 调整按钮的宽度 */
  .el-switch__core,
  .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}
</style>
