<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" :append-to-body="isAppend">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
            <!-- :on-preview="handlePictureCardPreview" -->
     <el-upload
        :limit="limit"
        :action="actionPath"
        list-type="picture-card"
        :before-upload="beforeAvatarUpload"
        :on-success="uploadOnSuccess"
        :data="data"
        :file-list="fileList|filterUrl"
        :on-exceed="handleExceed"
        :on-remove="handleRemove">
        <i class="el-icon-plus"></i>
        <div v-if="tips" slot="tip" class="el-upload__tip ml-2">
          {{tips}}
        </div>
      </el-upload>
  </div>
</template>

<script>


export default {
  name: 'uploadFile',
  props: {
    limit: {
      type: Number,
      default:1
    },
    actionPath: {
      type:  [String],
      default: 'http://upload.qiniup.com'
    },
    tips : {
      type:String,
      default:''
    },
    isAppend: {
       type:Boolean,
       default:true
    },
    data: {
      type:Object
    }, 
    fileList: {
      type:[Array,String],
    }    
  },
 
  filters:{
    filterUrl(url){
      if(!url){
        return []
      }
      console.log(12121212,url,Array.isArray(url))
      if(Array.isArray(url)){
        let pic =  url.map(v => {
          return {
            name:v,
            url:v
          }
        })
        return pic
      }else{
        return [{
           name:url,
            url:url
        }]
      }
    }
  },
 
  data(){
    return {
      dialogImageUrl:'',
      dialogVisible:false,
      qiniuData: {
        token: "",
        key: "",
      },
    }
  },
  methods: {
    //上传图片
    uploadOnSuccess(res, file, fileList){
      if(this.limit == 1){
        this.$emit('on-success',res.key);
      }else{
        console.log(123456,fileList)
        let urls = fileList.map(v=>{
          if(v.response){
            return v.response.key
          }else{
            return v.url
          }
        })
        console.log(1234567,urls)
        this.$emit('on-success',urls);
      }      
    },
    //图片预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    handleExceed(files, fileList) {
      this.$message.warning(`最多只能上传${this.limit}张图片`);
    },
    //删除图片
    handleRemove(file, fileList) {
      if(this.limit == 1){
        this.$emit('on-remove','');
      }else{
        let urls = fileList.map(v => v.response.key)
        this.$emit('on-remove', urls);
      }    
    },

    //图片上传前置见检测
    beforeAvatarUpload(file) {
      let name = new Date().getTime()+"_"+file.name   //给file.name加上时间戳保证图片命名不重复
      this.data.key = name; // 通过设置key为文件名，上传到七牛中会显示对应的图片名
      // debugger
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt10M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }

      return isJPG && isLt10M;
    },
  }
}
</script>

<style scoped>

</style>
